<template>
  <div
    v-if="havePersisions"
    class="rounded-xl shadow-xl px-10 py-10 min-h relative"
  >
    <h1 class="text-left text-3xl font-bold">
      Hoy <br /><span class="font-thin">{{ getDate }}</span>
    </h1>
    <AlertListBase
      :data="data"
      :loading="loading"
      :subtitle="subtitle"
      :filter_date="false"
      :alert_title="true"
      :loadMore="false"
      @viewed="viewed($event)"
    />
  </div>
</template>
<script>
import AlertListBase from "./AlertListBase.vue";
import moment from "moment";
import capitalize from "@/utils/checkForms.js";
export default {
  components: { AlertListBase },
  data() {
    return {
      loading: true,
      data: [],
      form: {
        createBetween: [
          moment().set(new Date()).subtract(30, "d").format("YYYY-MM-DD"),
          moment().set(new Date()).format("YYYY-MM-DD"),
        ],
      },
      page: 1,
      pageSize: 10,
      subtitle: "",
    };
  },
  created() {
    if (this.havePersisions) {
      this.getData();
    }
  },
  methods: {
    async getData(pageSize = this.pageSize) {
      this.loading = true;
      //request data
      let query = `?page_size=${pageSize}&page=${this.page}&start_date=${this.form.createBetween[0]}&end_date=${this.form.createBetween[1]}&alert_configuration_alert_type_id=`;
      let { data, error } = await this.$api.getAlerts(query);
      if (error) {
        this.loading = false;
        this.data = [];
        this.subtitle = `No hay notificaciones para el último mes`;
      }
      if (data) {
        this.loading = false;
        this.data = data.results.map((item) => {
          return {
            ...item,
            title: capitalize(item.configuration_alert_name),
            description: item.detail_alert,
            viewed: item.viewed,
          };
        });
        this.subtitle = `Últimas ${this.data.length} alertas del mes`;
        this.count = data.count;
      }
    },
    async viewed(item) {
      this.data.forEach((alert) => {
        if (alert.id == item) alert.viewed = true;
      });
      let { data, error } = await this.$api.setReadAlert({ alert: item });

      if (error) {
        this.data.forEach((alert) => {
          if (alert.id == item) alert.viewed = false;
        });
      } else if (data) {
        this.$store.dispatch("session/getNotifications");
      }
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
  },
  computed: {
    havePersisions: function () {
      let permissions = this.$store.state.session.modules?.filter(
        (module) => module.id == 28
      );
      return permissions?.length ? true : false;
    },
    getDate: function () {
      return moment().format("D") + " de" + " " + moment().format("MMMM");
    },
  },
   watch: {
    "$store.state.session.notifications.count": {
      handler(newVal, oldVal) {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.min-h {
  min-height: 30em;
}
</style>
